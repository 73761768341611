<template>
  <div id="pgr-payment-container"></div>
</template>
<script>
import config from "@/config";
import moment from "moment";
import { sumBy } from "lodash";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data: () => ({
    loaded: false,
    env: process.env.VUE_APP_ENV,
  }),
  props: {
    checkoutStep: String,
    updateRefundProtectQuote: Boolean,
  },
  computed: {
    ...mapGetters([
      "orderDetails",
      "storedTickets",
      "refoundProtectData",
      "checkoutList",
      "checkoutRegion",
    ]),
    ticketsQuantity() {
      return sumBy(this.storedTickets, "quantity");
    },
    eventDate() {
      return moment(this.checkoutList[0]?.event?.starts).format("DD/MM/YYYY");
    },
    vendorCode() {
      return config.checkout[this.checkoutRegion.toLowerCase()]
        ?.refundProtectVendorCode;
    },
    widgetData() {
      return {
        totalValue: this.orderDetails.total,
        numberOfTickets: this.ticketsQuantity,
        eventTravelDateTime: this.eventDate,
      };
    },
  },
  methods: {
    ...mapMutations(["setRefunfProtectData"]),
    ...mapActions(["getOrderDescribe"]),
    async initWidget() {
      (function (P, R, O, t, E, C, T) {
        P[t] =
          P[t] ||
          function () {
            (P[t].q = P[t].q || []).push(arguments);
          };
        (C = R.createElement(O)), (T = R.getElementsByTagName(O)[0]);
        C.id = t;
        C.src = E;
        C.async = 1;
        T.parentNode.insertBefore(C, T);
      })(
        window,
        document,
        "script",
        "_pgr",
        "https://test.widget.protectgroup.com/dynamic-widget.js"
      );

      window._pgr("init", {
        environment: this.env === "production" ? "prod" : "test",
        debug: false,
        vendorCode: this.vendorCode,
        currencyCode: "USD",
        languageCode: "en",
        eventDateFormat: "DD/MM/YYYY",
        useSaleAction: false,
      });

      document.addEventListener("PgWidgetLoaded", await this.onWidgetLoad);

      document.addEventListener(
        "PgWidgetProtectionChange",
        this.onWidgetUpdate
      );
    },
    async onWidgetLoad(event) {
      console.log("DEV: Widget loaded", event);
      this.loaded = true;
      window._pgr("action", "updateQuoteData", this.widgetData);
    },
    async onWidgetUpdate(event) {
      const { detail } = event;
      console.log("DEV: Widget updated", detail);

      this.$emit("set-process-status", true);
      this.setRefunfProtectData(detail);
      await this.getOrderDescribe({ includeRefundProtect: true });

      if (this.checkoutStep === "payment") {
        this.$emit("post-checkout-order");
        return;
      }

      this.$emit("set-process-status", false);
    },
  },
  watch: {
    updateRefundProtectQuote: {
      async handler(value) {
        if (!value) return;
        console.log("Update quete event", this.widgetData);
        this.$emit("set-process-status", true);
        // If totalValue doesn't change, event not shoot
        window._pgr("action", "updateQuoteData", this.widgetData);
      },
    },
  },
  created() {
    // this.$emit("set-process-status", true);
    this.initWidget();
  },
  destroyed() {
    console.log("Destroyed");
    window._pgr("destroy");
    document.removeEventListener("PgWidgetLoaded", this.onWidgetLoad);
    document.removeEventListener(
      "PgWidgetProtectionChange",
      this.onWidgetUpdate
    );
  },
};
</script>
